import React from 'react'
import facebook from '../img/social/facebook.svg'
import admin from '../img/pencil.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import youtube from '../img/social/youtube.svg'
import { MainFont } from '../components/Sampler'
import {
  logoSVGT1,
  logoSVGT2,
  logoSVGT3,
  logoSVGT4,
  logoSVGT5,
  logoSVGT6,
  logoSVGT7,
} from '../../static/svgtext'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="social">
          <div style={{display: 'flex', marginTop: '-25px', marginBottom: '20px', alignItems: 'center'}}>
            {/* <link
              href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
              rel="stylesheet"
            ></link> */}
            {/* <img
              src={logo}
              alt="OBC Logo"
              style={{
                width: '2.2em',
                height: '2.2em',
                lineHeight: 'none',
                display: 'inline-flex',
                marginLeft: 'auto',
                marginRight: '10px',
                marginTop: '8px'
              }}
            /> */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="160.72px" viewBox="0 0 1140.000000 1158.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                width: '2.2em',
                height: '2.2em',
                lineHeight: 'none',
                display: 'inline-flex',
                marginLeft: 'auto',
                marginRight: '10px',
                marginTop: '0px'
              }}>

              <g transform="translate(0.000000,1158.000000) scale(0.100000,-0.100000)"
                fill='white' stroke="none">
                <path d={logoSVGT1} />
                <path d={logoSVGT2} />
                <path d={logoSVGT3} />
                <path d={logoSVGT4} />
                <path d={logoSVGT5} />
                <path d={logoSVGT6} />
                <path d={logoSVGT7} />
              </g>
            </svg>
            <h1 style={{
              fontSize: '1.7em',
              color: '#dfdfdf',
              fontFamily: MainFont,
              display: 'inline-flex',
              marginRight: 'auto',
              marginTop: '8px',
              marginLeft: '-18px',
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
              fontWeight: MainFont === 'Maven Pro' ? 'bold' : null
            }}>
              nley Baptist Church
            </h1>
          </div>
          <a title="Facebook" target="_blank" href="https://www.facebook.com/OnleyBaptist">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
          {/* href={window.location.href + "admin/" */}
          <a title="Admin" href={"https://www.onleybaptistchurch.org/admin"}>
            <img
              src={admin}
              alt="Admin"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
        </div>
        <div className="content has-text-centered" style={{ paddingBottom: '30px'}}>
          <p id='copyright'>
            {'Copyright © ' + new Date().getFullYear() + ' Onley Baptist Church / All rights reserved / Privacy Policy'}
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
